import { FC, memo } from 'react'
import type { Props } from '../types'

const FilterArrow: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M13.9231 9.35894H10.6278C10.446 9.35894 10.2936 9.299 10.1707 9.17911C10.0477 9.05922 9.9862 8.91073 9.9862 8.73364C9.9862 8.55639 10.0477 8.4062 10.1707 8.28308C10.2936 8.16012 10.446 8.09864 10.6278 8.09864H13.9231L12.824 7.00606C12.683 6.8734 12.6125 6.72774 12.6125 6.56907C12.6125 6.41057 12.6789 6.26515 12.8116 6.13282C12.9481 5.99661 13.0981 5.9285 13.2614 5.9285C13.4245 5.9285 13.5765 5.99564 13.7173 6.12991L15.7456 8.1699C15.8236 8.24826 15.8855 8.33374 15.9312 8.42632C15.9771 8.5189 16 8.61811 16 8.72395C16 8.82671 15.9772 8.92471 15.9317 9.01793C15.8862 9.111 15.8242 9.19696 15.7458 9.27581L13.7081 11.3277C13.575 11.4598 13.4249 11.5249 13.2577 11.5228C13.0905 11.5207 12.9428 11.4556 12.8145 11.3277C12.6737 11.1941 12.6033 11.0482 12.6033 10.89C12.6033 10.7318 12.6737 10.5856 12.8145 10.4515L13.9231 9.35894ZM6.38391 5.9937L10.6572 1.2603H2.08608L6.38391 5.9937ZM5.47914 6.93529L0.139731 0.919535C0.0896689 0.869123 0.0538639 0.816529 0.0323161 0.761755C0.0107682 0.70698 -5.72205e-06 0.644531 -5.72205e-06 0.574407C-5.72205e-06 0.428341 0.0513526 0.29609 0.154069 0.177654C0.256948 0.0592184 0.396199 0 0.571822 0H12.1865C12.3621 0 12.5014 0.0592184 12.6043 0.177654C12.707 0.29609 12.7583 0.428341 12.7583 0.574407C12.7583 0.644531 12.7476 0.70698 12.726 0.761755C12.7045 0.816529 12.6687 0.869123 12.6186 0.919535L7.27919 6.93529V12.2242C7.27919 12.4439 7.20434 12.6282 7.05464 12.777C6.90478 12.9257 6.71919 13 6.49788 13H6.26045C6.03898 13 5.85339 12.9257 5.70369 12.777C5.55399 12.6282 5.47914 12.4439 5.47914 12.2242V6.93529Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(FilterArrow)
