import Analysis from './Icons/Analysis'
import ArrowRight from './Icons/Arrow/Arrow-right'
import ArrowLeft from './Icons/Arrow/Arrow-left'
import ArrowBack from './Icons/Arrow/Arrow-back'
import Clipboard from './Icons/Clipboard'
import ClipboardFilled from './Icons/ClipboardFilled'
import Close from './Icons/Close'
import Dots from './Icons/Sidebar/Dots'
import Download from './Icons/Download'
import Edit from './Icons/Edit'
import Evolution from './Icons/Evolution'
import Error from './Icons/Error'
import EyeClosed from './Icons/Eye/Closed/EyeClosed'
import EyeOpen from './Icons/Eye/Open/EyeOpen'
import OpenUrl from './Icons/OpenUrl'
import Share from './Icons/Share'
import Trash from './Icons/Trash'
import Amazon from './Icons/Networks/Amazon'
import Facebook from './Icons/Networks/Facebook'
import Twitter from './Icons/Networks/Twitter'
import Instagram from './Icons/Networks/Instagram'
import Youtube from './Icons/Networks/Youtube'
import Tiktok from './Icons/Networks/Tiktok'
import Media from './Icons/Networks/Media'
import Linkedin from './Icons/Networks/Linkedin'
import LinkedinInverted from './Icons/Networks/LinkedinInverted'
import Review from './Icons/Networks/Review'
import Google from './Icons/Networks/Google'
import InfoInactive from './Icons/InfoInactive'
import InfoActive from './Icons/InfoActive'
import ChevronUp from './Icons/Chevron/Chevron-up'
import ChevronDown from './Icons/Chevron/Chevron-down'
import ConfigFilled from './Icons/Config'
import Drive from './Icons/Products/Drive'
import Race from './Icons/Products/Race'
import Explore from './Icons/Products/Explore'
import ReviewProduct from './Icons/Products/Review'
import ReviewBenchmark from './Icons/Products/ReviewBenchmark'
import MediaProduct from './Icons/Products/Media'
import Summary from './Icons/Sidebar/Summary'
import Categories from './Icons/Sidebar/Categories'
import KeyConcepts from './Icons/Sidebar/KeyConcepts'
import Mentions from './Icons/Sidebar/Mentions'
import OfficialAccounts from './Icons/Sidebar/OfficialAccounts'
import Alerts from './Icons/Sidebar/Alerts'
import Integrations from './Icons/Sidebar/Integrations'
import Config from './Icons/Sidebar/Config'
import Trends from './Icons/Sidebar/Trends'
import TvMode from './Icons/Sidebar/TvMode'
import Plus from './Icons/Plus'
import Reports from './Icons/Sidebar/Reports'
import LearningCenter from './Icons/Sidebar/LearningCenter'
import FilledStar from './Icons/Star/FilledStar'
import OutlineStar from './Icons/Star/OutlineStar'
import FilterArrow from './Icons/FilterArrow'
import Emails from './Icons/Sidebar/Emails'

export const ICONS = {
  analysis: Analysis,
  clipboard: Clipboard,
  clipboardFilled: ClipboardFilled,
  close: Close,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  configFilled: ConfigFilled,
  dots: Dots,
  download: Download,
  edit: Edit,
  error: Error,
  evolution: Evolution,
  eyeClosed: EyeClosed,
  eyeOpen: EyeOpen,
  amazon: Amazon,
  facebook: Facebook,
  google: Google,
  instagram: Instagram,
  media: Media,
  openUrl: OpenUrl,
  review: Review,
  share: Share,
  tiktok: Tiktok,
  trash: Trash,
  twitter: Twitter,
  youtube: Youtube,
  linkedin: Linkedin,
  linkedinInverted: LinkedinInverted,
  infoInactive: InfoInactive,
  infoActive: InfoActive,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowBack: ArrowBack,
  drive: Drive,
  race: Race,
  explore: Explore,
  mediaProduct: MediaProduct,
  reviewProduct: ReviewProduct,
  reviewBenchmark: ReviewBenchmark,
  summary: Summary,
  categories: Categories,
  keyConcept: KeyConcepts,
  mentions: Mentions,
  officialAccount: OfficialAccounts,
  alerts: Alerts,
  integrations: Integrations,
  config: Config,
  trend: Trends,
  learningCenter: LearningCenter,
  reports: Reports,
  tvMode: TvMode,
  plus: Plus,
  filledStar: FilledStar,
  outlineStar: OutlineStar,
  filterArrow: FilterArrow,
  emails: Emails,
}
