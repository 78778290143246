import { FC, memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useLogic from './logic'
import * as S from './styles'
import { Props } from './types'

const Link: FC<Props> = ({
  children,
  external,
  to,
  className,
  onClick,
  title,
}) => {
  const { handleOnClick } = useLogic({ onClick })

  return (
    <S.Container onClick={handleOnClick} className={className}>
      {external ? (
        <S.Link
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
        >
          {children}
        </S.Link>
      ) : (
        <RouterLink to={to}>{children}</RouterLink>
      )}
    </S.Container>
  )
}

export default memo(Link)
