import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'
import {
  ModalConnectIntegrationPayload,
  ModalCreateReviewProductOptionsPayload,
  ModalDisconnectIntegrationPayload,
  ModalRemoveDialogPayload,
  ModalShareMentionPayload,
  ModalThemeFormPayload,
  ModalType,
  modalVar,
  resetModalVar,
  setModalVar,
  updateModalVar,
} from 'apollo/reactive/modal'

const useModal = () => {
  const {
    modalType,
    connectIntegrationPayload,
    disconnectIntegrationPayload,
    removeDialogPayload,
    shareMentionPayload,
    createReviewProductOptionsPayload,
    themeFormPayload,
  } = useReactiveVar(modalVar)

  const onCloseModal = useCallback((dataClearDelay?: number) => {
    updateModalVar({ modalType: null })
    if (dataClearDelay) {
      setTimeout(() => {
        resetModalVar()
      }, dataClearDelay)
    } else {
      resetModalVar()
    }
  }, [])

  const openConnectIntegrationModal = useCallback(
    (data: ModalConnectIntegrationPayload) => {
      setModalVar({
        modalType: ModalType.CONNECT_INTEGRATION,
        connectIntegrationPayload: data,
      })
    },
    [],
  )

  const openDisconnectIntegrationModal = useCallback(
    (data: ModalDisconnectIntegrationPayload) => {
      setModalVar({
        modalType: ModalType.DISCONNECT_INTEGRATION,
        disconnectIntegrationPayload: data,
      })
    },
    [],
  )

  const openRemoveDialogModal = useCallback(
    (data: ModalRemoveDialogPayload) => {
      setModalVar({
        modalType: ModalType.REMOVE_DIALOG,
        removeDialogPayload: data,
      })
    },
    [],
  )

  const openShareMentionModal = useCallback(
    (data: ModalShareMentionPayload) => {
      setModalVar({
        modalType: ModalType.SHARE_MENTION,
        shareMentionPayload: data,
      })
    },
    [],
  )

  const openCreateReviewModal = useCallback(
    (data: ModalCreateReviewProductOptionsPayload) => {
      setModalVar({
        modalType: ModalType.CREATE_REVIEW_PRODUCT_OPTIONS,
        createReviewProductOptionsPayload: data,
      })
    },
    [],
  )

  const openThemeForm = (data: ModalThemeFormPayload) => {
    setModalVar({
      modalType: ModalType.THEME_FORM,
      themeFormPayload: data,
    })
  }

  return {
    modalType,
    onCloseModal,
    connectIntegrationPayload,
    disconnectIntegrationPayload,
    removeDialogPayload,
    shareMentionPayload,
    createReviewProductOptionsPayload,
    openConnectIntegrationModal,
    openDisconnectIntegrationModal,
    openRemoveDialogModal,
    openShareMentionModal,
    openCreateReviewModal,
    openThemeForm,
    themeFormPayload,
  }
}

export default useModal
