import { FC, memo } from 'react'
import type { Props } from '../../types'

const Review: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="24" cy="24" r="24" fill="#A97AEB" />
    <g clipPath="url(#clip0_1107_40139)">
      <path
        d="M29.7239 26.3125L34.3256 22.585C34.9765 22.0557 34.6398 21.019 33.7868 20.9749L27.8608 20.622C27.5017 20.6 27.1874 20.3794 27.0527 20.0486L24.8754 14.5787C24.5611 13.8068 23.4612 13.8068 23.1469 14.5787L20.9696 20.0265C20.8349 20.3574 20.5206 20.5779 20.1615 20.6L14.213 20.9529C13.36 20.997 13.0233 22.0336 13.6743 22.563L18.2759 26.2684C18.5453 26.4889 18.68 26.8639 18.5902 27.1947L17.0862 32.8631C16.8842 33.6571 17.7596 34.2967 18.4779 33.8556L23.4836 30.7016C23.7979 30.5031 24.1795 30.5031 24.4713 30.7016L29.4994 33.8556C30.2177 34.2967 31.0932 33.6571 30.8912 32.8631L29.3872 27.2168C29.3199 26.8859 29.4321 26.533 29.7239 26.3125Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1107_40139">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(13.3333 13.3333)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default memo(Review)
