import { FC, memo, useRef } from 'react'
import useOverflow from './useOverflow'
import * as S from './styles'
import type { Props } from './types'

const Tooltip: FC<Props> = ({
  placement = 'bottom',
  children,
  className = '',
  showOnlyOnOverflow = false,
  title,
  ...rest
}) => {
  const labelRef = useRef(null)
  const isOverflowing = useOverflow(labelRef, title)

  return (
    <S.Tooltip
      placement={placement}
      className={className}
      disableHoverListener={(showOnlyOnOverflow && !isOverflowing) || !title}
      title={title}
      {...rest}
    >
      <S.Container ref={labelRef}>{children}</S.Container>
    </S.Tooltip>
  )
}

export default memo(Tooltip)
