import { FC, memo } from 'react'
import type { Props } from '../../types'

const ReviewBenchmark: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="18" cy="18" r="18" fill="#A97AEB" />
    <path
      d="M20.34 14.1731L21.8996 12.9098C22.1203 12.7303 22.0062 12.379 21.7171 12.3641L19.7086 12.2445C19.5869 12.237 19.4803 12.1622 19.4347 12.0501L18.6967 10.1962C18.5902 9.93459 18.2174 9.93459 18.1109 10.1962L17.373 12.0426C17.3273 12.1548 17.2208 12.2295 17.0991 12.237L15.083 12.3566C14.7939 12.3715 14.6798 12.7229 14.9004 12.9023L16.46 14.1581C16.5513 14.2329 16.597 14.36 16.5665 14.4721L16.0568 16.3932C15.9883 16.6623 16.2851 16.8791 16.5285 16.7296L18.2251 15.6607C18.3316 15.5934 18.4609 15.5934 18.5598 15.6607L20.264 16.7296C20.5074 16.8791 20.8041 16.6623 20.7356 16.3932L20.2259 14.4796C20.2031 14.3674 20.2411 14.2478 20.34 14.1731Z"
      fill="white"
    />
    <path
      d="M15.5552 22.8341L17.1148 21.5708C17.3354 21.3914 17.2213 21.04 16.9322 21.0251L14.9237 20.9055C14.802 20.898 14.6955 20.8233 14.6499 20.7111L13.9119 18.8573C13.8054 18.5956 13.4326 18.5956 13.3261 18.8573L12.5881 20.7037C12.5425 20.8158 12.436 20.8905 12.3143 20.898L10.2982 21.0176C10.0091 21.0326 9.89496 21.3839 10.1156 21.5633L11.6752 22.8192C11.7665 22.8939 11.8121 23.021 11.7817 23.1331L11.272 25.0543C11.2035 25.3234 11.5002 25.5402 11.7437 25.3907L13.4402 24.3217C13.5467 24.2544 13.6761 24.2544 13.775 24.3217L15.4791 25.3907C15.7226 25.5402 16.0193 25.3234 15.9508 25.0543L15.4411 23.1406C15.4183 23.0285 15.4563 22.9089 15.5552 22.8341Z"
      fill="white"
    />
    <path
      d="M25.1248 22.8341L26.6844 21.5708C26.905 21.3914 26.7909 21.04 26.5018 21.0251L24.4933 20.9055C24.3716 20.898 24.2651 20.8233 24.2195 20.7111L23.4815 18.8573C23.375 18.5956 23.0022 18.5956 22.8957 18.8573L22.1577 20.7037C22.1121 20.8158 22.0056 20.8905 21.8839 20.898L19.8678 21.0176C19.5787 21.0326 19.4646 21.3839 19.6852 21.5633L21.2448 22.8192C21.3361 22.8939 21.3817 23.021 21.3513 23.1331L20.8416 25.0543C20.7731 25.3234 21.0698 25.5402 21.3133 25.3907L23.0098 24.3217C23.1163 24.2544 23.2457 24.2544 23.3446 24.3217L25.0487 25.3907C25.2922 25.5402 25.5889 25.3234 25.5204 25.0543L25.0107 23.1406C24.9879 23.0285 25.0259 22.9089 25.1248 22.8341Z"
      fill="white"
    />
  </svg>
)

export default memo(ReviewBenchmark)
