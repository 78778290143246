import { set, flow, getOr } from 'lodash/fp'

import {
  ADD_CATEGORY,
  SET_MENTIONS_FILTERS,
  CLEAR_MENTIONS_FILTERS,
  REQ_META_MENTIONS_FILTERS,
  RES_META_MENTIONS_FILTERS,
  REQ_META_LOCATIONS_FILTERS,
  RES_META_LOCATIONS_FILTERS,
  REQ_META_REVIEW_PLATFORMS_FILTERS,
  RES_META_REVIEW_PLATFORMS_FILTERS,
  REQ_META_REVIEW_PRODUCTS_FILTERS,
  RES_META_REVIEW_PRODUCTS_FILTERS,
} from '../actions/mentionsFiltersActions'
import {
  parseToMarketCategories,
  parseToCustomCategories,
} from '@views/Config/Analysis/helpers'

/**
 * Update the filters versions
 */
const updateVersion = state => {
  const currentVersion = getOr(0, 'meta.version', state)
  return set('meta.version', currentVersion + 1, state)
}

/**
 * Mentions filter reducer
 */

const initialState = {
  filters: {
    category: { or: [], and: [], not: [] },
    theme: { or: [] },
    feed: { or: [], not: [] },
    sentiment: { or: [] },
    text: {
      or: [],
      and: [],
      not: [],
      match: '',
    },
    mentions: { or: [] },
    aspect: { or: [] },
    mainMessage: false,
    uncategorized: false,
    isDark: false,
    store: { or: [], not: [] },
    geoLocation: {
      country: { or: [], not: [] },
      county: { or: [], not: [] },
      locality: { or: [], not: [] },
    },
    storeMetadata: {},
    reviewPlatform: { or: [], not: [] },
    reviewProduct: { or: [], not: [] },
  },
  isFetching: false,
  categories: [],
  meta: { version: 0 },
}

export default function globalFilters(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ADD_CATEGORY:
      return set('categories', state.categories.concat(payload), state)

    case SET_MENTIONS_FILTERS:
      return flow(set('filters', payload), updateVersion)(state)

    case CLEAR_MENTIONS_FILTERS:
      return flow(set('filters', {}), updateVersion)(state)

    case REQ_META_MENTIONS_FILTERS:
      return flow(
        set('isFetching', true),
        set('categories', []),
        set('themes', []),
      )(state)

    case RES_META_MENTIONS_FILTERS:
      return flow(
        set('isFetching', false),
        set('categories', payload.categories),
        set('themes', payload.themes),
      )(state)

    case REQ_META_LOCATIONS_FILTERS:
      return flow(set('isFetching', true), set('locations', []))(state)

    case RES_META_LOCATIONS_FILTERS:
      return flow(
        set('isFetching', false),
        set('locations', payload.locations),
      )(state)

    case REQ_META_REVIEW_PRODUCTS_FILTERS:
      return flow(set('isFetching', true), set('reviewProducts', []))(state)

    case RES_META_REVIEW_PRODUCTS_FILTERS:
      return flow(
        set('isFetching', false),
        set('reviewProducts', payload.reviewProducts),
      )(state)

    case REQ_META_REVIEW_PLATFORMS_FILTERS:
      return flow(set('isFetching', true), set('reviewPlatforms', []))(state)

    case RES_META_REVIEW_PLATFORMS_FILTERS:
      return flow(
        set('isFetching', false),
        set('reviewPlatforms', payload.reviewPlatforms),
      )(state)

    default:
      return state
  }
}

export const getMarketCategories = state =>
  parseToMarketCategories(state.mentionsFilters.categories)
export const getCustomCategories = state =>
  parseToCustomCategories(state.mentionsFilters.categories)
