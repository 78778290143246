import { FC, memo } from 'react'
import type { Props } from '../../types'

const FilledStar: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clip-path="url(#clip0_5628_10252)">
      <path
        d="M12.293 9.85017L15.7442 6.86821C16.2325 6.44474 15.9799 5.61543 15.3402 5.58014L10.8957 5.29783C10.6263 5.28018 10.3906 5.10374 10.2896 4.83907L8.65658 0.463174C8.42088 -0.154391 7.59595 -0.154391 7.36026 0.463174L5.72724 4.82142C5.62623 5.08609 5.39053 5.26254 5.12117 5.28018L0.659819 5.5625C0.020078 5.59779 -0.232451 6.42709 0.255772 6.85056L3.707 9.81488C3.90903 9.99133 4.01004 10.2913 3.9427 10.556L2.81473 15.0907C2.66322 15.7259 3.31979 16.2376 3.85852 15.8847L7.61279 13.3615C7.84848 13.2027 8.13468 13.2027 8.35354 13.3615L12.1246 15.8847C12.6634 16.2376 13.3199 15.7259 13.1684 15.0907L12.0405 10.5736C11.99 10.3089 12.0741 10.0266 12.293 9.85017Z"
        fill="#FFD500"
      />
    </g>
    <defs>
      <clipPath id="clip0_5628_10252">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(FilledStar)
