import { FC, memo } from 'react'
import type { Props } from '../../types'

const Emails: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2015 1.50893H1.09242V10.3258C1.09242 10.4013 1.11665 10.4633 1.16511 10.5118C1.21357 10.5602 1.27554 10.5844 1.35103 10.5844H8.82341C8.97845 10.5844 9.10828 10.6363 9.2129 10.7399C9.31738 10.8437 9.36963 10.9725 9.36963 11.1262C9.36963 11.28 9.31738 11.4102 9.2129 11.5168C9.10828 11.6235 8.97845 11.6769 8.82341 11.6769H1.35103C0.98255 11.6769 0.665257 11.5438 0.399154 11.2777C0.133051 11.0116 0 10.6943 0 10.3258V1.76754C0 1.39905 0.133051 1.08176 0.399154 0.815658C0.665257 0.549555 0.98255 0.416504 1.35103 0.416504H13.9429C14.3212 0.416504 14.6409 0.549555 14.9021 0.815658C15.1633 1.08176 15.2939 1.39905 15.2939 1.76754V6.71894C15.2939 6.87398 15.242 7.00381 15.1382 7.10843C15.0346 7.21291 14.9059 7.26515 14.7521 7.26515C14.5983 7.26515 14.4682 7.21291 14.3616 7.10843C14.2549 7.00381 14.2015 6.87398 14.2015 6.71894V1.50893ZM1.09242 1.50893V10.5844V7.04226V7.09541V1.50893ZM7.64696 5.26938L14.2015 1.50893V2.82593L8.00578 6.38764C7.89401 6.45221 7.77882 6.48449 7.66019 6.48449C7.54143 6.48449 7.42441 6.45221 7.30915 6.38764L1.09242 2.89063V1.50893L7.64696 5.26938ZM14.1628 11.6769H11.2654C11.1108 11.6769 10.9813 11.625 10.877 11.5212C10.7728 11.4176 10.7207 11.2888 10.7207 11.1351C10.7207 10.9813 10.7728 10.8511 10.877 10.7445C10.9813 10.6378 11.1108 10.5844 11.2654 10.5844H14.1708L13.1379 9.55147C13.0303 9.44391 12.9739 9.31555 12.9685 9.16639C12.9631 9.01723 13.0185 8.88376 13.1349 8.76597C13.2458 8.65645 13.3759 8.60435 13.5252 8.60967C13.6744 8.615 13.8032 8.67207 13.9116 8.78089L15.7847 10.6582C15.855 10.7288 15.9085 10.8029 15.9452 10.8807C15.9817 10.9585 16 11.0427 16 11.1332C16 11.2182 15.9817 11.301 15.9452 11.3815C15.9085 11.462 15.8578 11.5346 15.7933 11.5993L13.9074 13.4852C13.791 13.6015 13.6644 13.6534 13.5276 13.6411C13.3907 13.6287 13.2668 13.567 13.1559 13.456C13.0395 13.3397 12.9813 13.2123 12.9813 13.0739C12.9813 12.9354 13.0395 12.808 13.1559 12.6918L14.1628 11.6769Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Emails)
