import { makeVar } from '@apollo/client'
import type { CategoriesOptions, Theme, ThemeMeta } from 'models/Theme'
import type { SendMentionEmailsMessageInput } from 'apollo/generated/globalTypes'

export enum ModalType {
  REMOVE_DIALOG,
  SHARE_MENTION,
  CONNECT_INTEGRATION,
  DISCONNECT_INTEGRATION,
  CREATE_REVIEW_PRODUCT_OPTIONS,
  THEME_FORM,
}

export type ModalDisconnectIntegrationPayload = {
  desc: string
  submit: () => void
}

export type ModalConnectIntegrationPayload = {
  title: string
  desc: string
  submit: () => void
}

export type ModalRemoveDialogPayload = {
  acceptButtonText?: string
  cancelButtonText?: string
  description?: string
  onClose?: () => void
  onRemove: () => void
  title?: string
  body?: React.ReactNode
  hasToConfirm?: {
    name?: string
    confirmName: string
    label?: string
    placeholder?: string
  }
}

export type ModalShareMentionPayload = {
  mention: SendMentionEmailsMessageInput
}

export type ModalCreateReviewProductOptionsPayload = {
  brandId: string
}

export type ModalThemeFormPayload = {
  themeInfo: ThemeMeta
  categoriesOptions: CategoriesOptions
  onClose: (data?: Theme) => void
}

export type Modal = {
  modalType: ModalType | null
  connectIntegrationPayload: ModalConnectIntegrationPayload | null
  disconnectIntegrationPayload: ModalDisconnectIntegrationPayload | null
  removeDialogPayload: ModalRemoveDialogPayload | null
  shareMentionPayload: ModalShareMentionPayload | null
  createReviewProductOptionsPayload: ModalCreateReviewProductOptionsPayload | null
  themeFormPayload: ModalThemeFormPayload | null
}

const emptyModal: Modal = {
  modalType: null,
  connectIntegrationPayload: null,
  disconnectIntegrationPayload: null,
  removeDialogPayload: null,
  shareMentionPayload: null,
  createReviewProductOptionsPayload: null,
  themeFormPayload: null,
}

export const modalVar = makeVar<Modal>(emptyModal)

export const resetModalVar = () => {
  modalVar(emptyModal)
}

export const setModalVar = (data: Partial<Modal>) => {
  modalVar({ ...emptyModal, ...data })
}

export const updateModalVar = (data: Partial<Modal>) => {
  modalVar({ ...modalVar(), ...data })
}
