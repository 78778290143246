import { FC, memo } from 'react'
import type { Props } from '../../types'

const OutlineStar: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.3243 5.82964V5.82965L15.3264 5.82976C15.7162 5.85126 15.9184 6.38571 15.5808 6.67904C15.5807 6.67914 15.5805 6.67925 15.5804 6.67935L12.1325 9.65841C11.836 9.89979 11.7295 10.2777 11.7949 10.6205L11.7945 10.6205L11.7979 10.6342L12.9253 15.1487C12.9253 15.149 12.9254 15.1494 12.9255 15.1498C12.9767 15.3661 12.8913 15.5538 12.7531 15.6599C12.6173 15.7642 12.4348 15.7884 12.2626 15.6761C12.2623 15.6759 12.2619 15.6757 12.2616 15.6755L8.4962 13.1561C8.18728 12.9346 7.78851 12.9416 7.4731 13.1541L3.7204 15.6763C3.54826 15.7884 3.36581 15.7642 3.23005 15.6599C3.09182 15.5538 3.00648 15.3661 3.05766 15.1497C3.05774 15.1494 3.05783 15.149 3.05791 15.1487L4.18498 10.6176C4.18503 10.6174 4.18508 10.6172 4.18514 10.617C4.27645 10.257 4.14105 9.86204 3.87146 9.62658L3.8699 9.62523L0.419581 6.66171C0.419432 6.66158 0.419283 6.66145 0.419134 6.66132C0.0816616 6.36798 0.283878 5.83362 0.673588 5.81212L0.673589 5.81213L0.675607 5.812L5.13696 5.52969L5.13751 5.52965C5.50856 5.50534 5.82646 5.26257 5.96081 4.91056L5.96134 4.90914L7.59383 0.552315C7.59391 0.552102 7.59399 0.551889 7.59407 0.551676C7.74811 0.149497 8.26856 0.149441 8.4227 0.551508C8.4228 0.551777 8.42291 0.552046 8.42301 0.552315L10.0554 4.92648L10.056 4.92821C10.1904 5.28022 10.5083 5.52299 10.8793 5.54729L10.8798 5.54733L15.3243 5.82964Z"
      fill="white"
      stroke="#A4AFBB"
      stroke-width="0.5"
    />
  </svg>
)

export default memo(OutlineStar)
