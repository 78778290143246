import { FC, memo } from 'react'
import type { Props } from '../../types'

const Race: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width="36" height="36" rx="18" fill="#22D6CB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1238 10.135L11.6531 18.952C11.4362 19.2016 11.6144 19.5878 11.9451 19.5878H17.9577C18.0286 19.5878 18.0801 19.655 18.0619 19.7222L16.4868 25.5142C16.3794 25.9089 16.8851 26.1744 17.1535 25.8641L24.3483 17.2806C24.563 17.032 24.3848 16.647 24.0552 16.647H18.1392C18.0683 16.647 18.0168 16.5787 18.0361 16.5104L19.7873 10.4923C19.9032 10.0966 19.3954 9.82351 19.1238 10.135Z"
      fill="white"
    />
  </svg>
)

export default memo(Race)
