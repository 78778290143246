import { gql } from '@apollo/client'
import { BRAND_FRAGMENT } from 'apollo/fragments/Project'
import { FEED_FRAGMENT } from 'apollo/fragments/Feed'
import { CATEGORY_FRAGMENT } from 'apollo/fragments/Category'
import { CUSTOM_RULE_FRAGMENT } from 'apollo/fragments/CustomRule'
import { VIEW_FRAGMENT } from 'apollo/fragments/View'

export const BASE_PRODUCT_FRAGMENT = gql`
  fragment BaseProductData on Product {
    id
    countries
    exclusiveSources
    languages
    name
    sentiment_rounding
    slug
    status
    type
    locationsMetadataFile {
      filename
      loadError
    }
    webhook {
      v
      enabled
      internal
      url
    }
    biDockConfig {
      enabled
      url
      timezone
      exportEveryMinutes
      platforms
      credentials {
        database
        password
        schema
        username
        host
        port
        ssl
      }
    }
  }
`

export const PRODUCT_FRAGMENT = gql`
  fragment ProductData on Product {
    ...BaseProductData
    brand {
      ...BrandData
    }
    feeds {
      ...FeedData
    }
    categories {
      ...CategoryData
    }
    customRules {
      ...CustomRuleData
    }
    views {
      ...ViewData
    }
  }
  ${BASE_PRODUCT_FRAGMENT}
  ${BRAND_FRAGMENT}
  ${FEED_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${CUSTOM_RULE_FRAGMENT}
  ${VIEW_FRAGMENT}
`
